<template>
  <div :class="backgroundClasses" :id="id" style="position: relative">
    <div class="section-content">
      <div class="section-title" v-if="title">{{ title }}</div>
      <div class="section-subtitle pt-6 mt-8 mb-8" v-if="subtitle">
        {{ subtitle }}
      </div>
      <v-container fluid class="section-text ma-0 pa-0">
        <slot></slot>
      </v-container>
    </div>
  </div>
</template>

<script>
import Section from "./Section";

export default {
  name: "BaseSection",
  extends: Section,
  props: {
    subtitle: {
      type: String,
      default: function () {
        return null;
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>
