<template>
    <div :class="backgroundClasses" :id="id" style="position:relative">
      <div class="section-content">
        <div class="section-title" v-if="title">{{ title }}</div>
        <div class="section-text" v-html="content" />
      </div>
    </div>
</template>

<script>
export default {
  name: "Section",
  props: {
    id: {
      type: String,
      default: function() {
        return null;
      }
    },
    title: {
      type: String,
      default: function() {
        return null;
      }
    },
    content_file: {
      type: String,
      default: function() {
        return null;
      }
    },
    background: {
      type: String,
      default: function() {
        return null;
      }
    }
  },
  data: () => ({}),
  computed: {
    backgroundClasses() {
      var clazz = {section: true};
      if (this.background) {
        clazz[this.background] = true;
      }
      return clazz;
    },

    content() {
      if (this.content_file) {
        const content = require("!!raw-loader!../assets/content/" + this.content_file).default;
        return content;
      }
      return "No content";
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>
