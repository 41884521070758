<template>
  <div class="intro-section">
    <div class="intro-content">
      <div class="intro-title-container">
        <div class="intro-title">BYGGNATION AV TELEANLÄGGNINGAR</div>
      </div>
      <div class="intro-title-container">
        <div class="intro-title">I JÄRNVÄG- OCH INFRASTRUKTURPROJEKT</div>
      </div>
      <div class="intro-text-container">
        <div class="intro-text">
          <p>
            Sverige är drygt 450 000 km<sup>2</sup> stort och bara fyra länder i
            Europa är större. För att binda ihop alla landsändar och få landet
            att fungera lika bra oavsett var man befinner sig, är god
            infrastruktur en av samhällets hörnstenar. Precis det som vi på
            Teleentreprenad arbetar med dagligen.
          </p>
          <p>
            Vi är en entreprenör som arbetar med byggnation av teleanläggningar
            i järnväg- och infrastrukturprojekt. Vi bygger opto- och
            kopparanläggningar samt informationssystem över hela Sverige, främst
            som underentreprenör. Behöver du hyra in personal, hjälper vi även
            till med det. Över hela landet.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>
