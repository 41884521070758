<template>
  <div>
    <div class="section-text mt-0">
      <b>{{ project.title }}</b>
    </div>
    <div class="section-text">
      <p class="pre-line">{{ project.text }}</p>
      <p class="linked" v-html="project.info"></p>
      <p><b>Utförandetid:</b> {{ project.time }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Project",
  props: {
    project: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>