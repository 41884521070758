<template>
  <v-img
    style="position: absolute; top: 0; left: 0; right: 0; bottom: 0"
    cover
    transition="false"
    :src="require('@/assets/stefano-ciociola-6TePgVf1CvI-unsplash.jpg')"
  >
    <v-container fill-height fluid pa-8>
      <v-row align="center" justify="center">
        <v-col class="" cols="auto">
          <div class="intro-title error-title mb-8">
            OOPS! sidan du letar<br />efter finns TYVÄRR inte.
          </div>
          <v-btn
            class="orange-button mt-8 ml-4"
            style="min-width: 200px !important"
            text
            @click.stop="goBackFromErrorPage"
          >
            <span>GÅ TILLBAKA</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
export default {
  name: "Error",
  methods: {
    goBackFromErrorPage() {
      window.location.href = "./";
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>
