export default function getAllProjects() {
  return [
    {
      title: "Malmö C",
      text:
        "Byggnation samt signaltrimning av trafikinformation vid spår 11 på ny plattform och befintliga plattformar på Malmö Central övre. Trafikinformationen innefattar högtalare, plattformsskyltar, TFT-skyltar samt statiska och taktila skyltar.",
      info: "UE åt <a href='http://www.nrcgroup.se' target='_blank'>NRC Group</a>",
      time: "2023-2024",
    },
    {
      title: "Charlottenberg Resecentrum",
      text:
        "Ombyggnation av plattform 1 och 2/3. Montage och installation av nya dynamiska skyltar, TFT-skärmar, högtalare, ur och statiska skyltar på plattformar. Kabelanläggningar till nya objekt på plattformar. Nya multidukter och blåsfiber till vxv-skåp.",
      info: "UE åt <a href='http://www.veidekke.se/' target='_blank'>Veidekke</a>",
      time: "2023",
    },
    {
      title: "Rååbanan etapp 2, kontaktlednings- och hjälpkraftbyte",
      text:
        "Ombyggnation av MOK-kabel vid ny kanalisation samt skydd och omskarvning av MOK- och optokabel vid fundamentssättning.",
      info: "UE åt <a href='http://www.infrakraft.se' target='_blank'>Infrakraft</a>",
      time: "2023",
    },
    {
      title: "Karlstad C och Vikenpassagen",
      text:
        "Ombyggnation av MOK och Opto i samband med nya plattformar. Nya avgreningar för mellanortskabel för kommunikation till kiosker och kurer. Montage och installation av nya dynamiska skyltar, högtalare och ur på plattformar. Ny blåsfiberanläggning till nytt trafikinformationssystem, växelvärme och kameraanläggning, samt kopparkablar till högtalaranläggning på plattform. Installationer inom nya teknikbyggnader.",
      info: "UE åt <a href='http://www.infrakraft.se' target='_blank'>Infrakraft</a>",
      time: "2023-2025",
    },
    {
      title: "Stockholm C – CST kraftseparering",
      text:
        "Ombyggnation av plattformar, samt gångtunnlar.\n\nMontage och installation av nya dynamiska skyltar, högtalare, ur och statiska skyltar på plattformar. Kabelanläggningar till nya objekt på plattformar.",
      info: "UE åt <a href='http://www.infrakraft.se' target='_blank'>Infrakraft</a>",
      time: "2022-2025",
    },
    {
      title: "Opto 2.0 Åby – Nässjö",
      text:
        "Nyförläggning av mikrokabel mellan Åby – Nässjö. Blåsning och skarvning av ca 380 000 m mikrokabel Inskarvning av befintliga siter med nya optokablar. Förberedande arbeten för kommande ERTMS-projekt.",
      info: "UE åt <a href='http://www.nyabab.se' target='_blank'>NYAB</a>",
      time: "2022-2023",
    },
    {
      title: "Säter",
      text:
        "Montage och installation av nya dynamiska skyltar, högtalare och ur på plattformar. Ny blåsfiberanläggning till nytt trafikinformationssystem och växelvärme, samt kopparkablar till högtalaranläggning på plattform.\n\nOmförläggning av MOK och optokabel i samband med ombyggnation av plattform. Nya avgreningar för mellanortskabel för kommunikation till kiosker och kurer.",
      info: "UE åt <a href='http://www.nrcgroup.se' target='_blank'>NRC Group</a>",
      time: "2022-2023",
    },
    {
      title: "Gävle-Storvik, Hastighetshöjande åtgärder",
      text:
        "Ombyggnation av MOK och Opto i samband hastighetshöjande åtgärder. Nya avgreningar för mellanortskabel för kommunikation till kiosker och kurer.",
      info: "UE åt <a href='http://www.nrcgroup.se' target='_blank'>NRC Group</a>",
      time: "2022-2023",
    },
    {
      title: "Värö – Bangårdsåtgärder",
      text: "Omförläggning och kontroll av befintliga telekablar i samband med bangårdsombyggnad.",
      info: "UE åt <a href='http://www.rrcrail.se' target='_blank'>RRC Rail</a>",
      time: "2022",
    },
    {
      title: "FA28 – BEST (Mölndal)",
      text:
        "Nybyggnation av plattform i Mölndal. Ny trafikinformation i form av TFT-skärm, toppbox, prator och högtalare.",
      info: "UE åt <a href='http://www.nrcgroup.se' target='_blank'>NRC Group</a>",
      time: "2022-2023",
    },
    {
      title: "Mjölby Trimningsåtgärder",
      text:
        "Ombyggnation av MOK och Opto i samband med ny plattform. Ny blåsfiberanläggning till nytt trafikinformationssystem och kameraanläggning, samt kopparkablar till högtalaranläggning på plattform. Montage och installation av nya dynamiska skyltar på plattform.",
      info: "UE åt <a href='http://www.infrakraft.se' target='_blank'>Infrakraft</a>",
      time: "2022-2023",
    },
    {
      title: "Spårbyte – Kil–Laxå",
      text:
        "Kontroll av befintlig anläggning i samband med spårbyte. Ombyggnad av befintlig mellanortskabel, omförläggning av befintliga kablar, samt blåsfiber till Vxv-skåp.",
      info: "UE åt <a href='http://www.infrakraft.se' target='_blank'>Infrakraft</a>",
      time: "2022-2023",
    },
    {
      title: "Ny växelförbindelse Hallsberg",
      text: "Nybyggnation av multidukt med blåsfiber till växelvärmeskåp och styrskåp för larm och passage.",
      info: "UE åt <a href='http://www.infrakraft.se' target='_blank'>Infrakraft</a>",
      time: "2021-2022",
    },
    {
      title: "Sävenäs spår 51",
      text:
        "Om- och nybyggnation av optoanläggningar för flytt av signalkiosk. Om- och ny förläggning av multidukt med blåsfiber till befintliga styrskåp för grindar, växelvärmeskåp, kamera-anläggning, samt RFID.",
      info: "UE åt <a href='http://www.ankerab.se' target='_blank'>Anker AB</a>",
      time: "2021-2022",
    },
    {
      title: "Sävenäs ställverksbyte",
      text:
        "Byggnation av nya optoanläggningar till nya och befintliga teknikbyggnader. Blåsfiber till nya Vxv- och styrskåp för grindar, samt byggnation av -48v kraft mm i flertalet teknikhus.",
      info: "UE åt <a href='http://www.infrakraft.se' target='_blank'>Infrakraft</a>",
      time: "2020-2022",
    },
    {
      title: "Välsviken, mötesspår",
      text:
        "Ombyggnation av MOK och Opto i samband med att nytt mötesspår och dubbla plattformar. Ny blåsfiber till Vxv, nytt trafikinformationssystem. Byggnation av -48v kraft mm i flertalet teknikhus.",
      info: "UE åt <a href='http://www.infrakraft.se' target='_blank'>Infrakraft</a>",
      time: "2020-2021",
    },
    {
      title: "Dunsjö – Jakobshyttan",
      text:
        "Byggnation av Opto 2.0-nät mellan Runsala och Jakobshyttan. Anslutning av nya och befintliga radiositer som förberedande arbete inför utbyggande av ERTMS. Ny blåsfiber till Vxv och transformatorkiosk. Byggnation av -48v kraft mm i flertalet teknikhus och telekiosker. Förberedande arbeten för tunnelradio i samband med ny spårlinje genom tunnel och bergsskärning.",
      info: "UE åt <a href='http://www.infrakraft.se' target='_blank'>Infrakraft</a>",
      time: "2021-2023",
    },
    {
      title: "Ombenning, ny mötesstation",
      text:
        "Ombyggnation av MOK- och Optoanläggningar i samband med nytt mötesspår. Byggnation av nya avgreningar på linjen mellan Fagersta och Snyten. Installation av ny -48v system i 2 nya teknikhus.",
      info: "UE åt <a href='http://www.infrakraft.se' target='_blank'>Infrakraft</a>",
      time: "2020-2021",
    },
    {
      title: "Fjärrblockering Fagersta-Ludvika",
      text:
        "Byggnation av Opto 2.0-nät mellan Fagersta och Ludvika. Samt byggnation av -48v kraft mm i flertalet teknikhus.",
      info: "UE åt <a href='http://www.infrakraft.se' target='_blank'>Infrakraft</a>",
      time: "2020-2021",
    },
    {
      title: "Plattformshöjning Uddevalla Östra",
      text: "Ombyggnation av MOK och nytt informationssystem.",
      info: "UE åt <a href='http://www.elektrotjanst-katrineholm.se/' target='_blank'>Elektrotjänst i Katrineholm</a>",
      time: "2020",
    },
    {
      title: "Mötesspår Grohed",
      text: "Ombyggnation av MOK och Opto i samband med ett nytt mötesspår.",
      info: "UE åt <a href='http://www.infrakraft.se' target='_blank'>Infrakraft</a>",
      time: "2020",
    },
    {
      title: "FA28 - BEST",
      text:
        "Ombyggnation av MOK och Opto samt flytt av teknikhus i samband med att provisoriska spår byggs för Västlänken.",
      info: "UE åt <a href='http://www.nrcgroup.se' target='_blank'>NRC Group</a>",
      time: "2020-2021",
    },
    {
      title: "ERTMS Malmbanan Gällivare-Råtsi Opto 2.0",
      text: "Blåsning och skarvning av ca 365 000 m mikrokabel.",
      info: "UE åt <a href='http://www.nyabab.se' target='_blank'>NYAB</a>",
      time: "2019",
    },
    {
      title: "Rosshyttan, bangårdsförlängning",
      text: "Nyförläggning och inskarvning av MOK och Opto, blåsfiber till Vxv. Nya avgreningar på MOK.",
      info: "UE åt <a href='http://www.elektrotjanst-katrineholm.se/' target='_blank'>Elektrotjänst i Katrineholm</a>",
      time: "2019",
    },
    {
      title: "DSP Hallsberg",
      text: "Nyförläggning av Opto- och MOK-anläggningar. Blåsning opto till Vxv och styrsystem.",
      info: "UE åt <a href='http://www.nrcgroup.se' target='_blank'>NRC Group</a>",
      time: "2019-2020",
    },
  ];
}
